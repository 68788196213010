// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureItem-module--root--1MAH4";
export var freeBox__kwdxa = "PlasmicFeatureItem-module--freeBox__kwdxa--3rWfl";
export var freeBox__uUx10 = "PlasmicFeatureItem-module--freeBox__uUx10--2l5w9";
export var freeBox__lFrRu = "PlasmicFeatureItem-module--freeBox__lFrRu--1Rd-e";
export var freeBox__x6TqV = "PlasmicFeatureItem-module--freeBox__x6TqV--2qaVj";
export var freeBox__nbWzj = "PlasmicFeatureItem-module--freeBox__nbWzj--2SGQ1";
export var freeBox__siXy0 = "PlasmicFeatureItem-module--freeBox__siXy0--W2Cvf";
export var freeBox___7QoWw = "PlasmicFeatureItem-module--freeBox___7QoWw--2wJPz";
export var freeBox__sdWiW = "PlasmicFeatureItem-module--freeBox__sdWiW--2impA";
export var freeBox__joQll = "PlasmicFeatureItem-module--freeBox__joQll--3g-JC";
export var freeBox__dE7EY = "PlasmicFeatureItem-module--freeBox__dE7EY--1R8bP";
export var linkButton = "PlasmicFeatureItem-module--linkButton--JMszT";
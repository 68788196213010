// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCarousel-module--root--1KCqq";
export var freeBox__jbtBx = "PlasmicProductCarousel-module--freeBox__jbtBx--2I507";
export var freeBox__rfMzr = "PlasmicProductCarousel-module--freeBox__rfMzr--3tRPB";
export var freeBox__vSLiZ = "PlasmicProductCarousel-module--freeBox__vSLiZ--1XYkT";
export var freeBox__gpwcs = "PlasmicProductCarousel-module--freeBox__gpwcs--1YnPL";
export var freeBox__yn3R = "PlasmicProductCarousel-module--freeBox__yn3R--2wg-p";
export var freeBox__pTcMw = "PlasmicProductCarousel-module--freeBox__pTcMw--1Hz2S";
export var freeBox__ekwG = "PlasmicProductCarousel-module--freeBox__ekwG--2dxJM";
export var freeBox__vacd4 = "PlasmicProductCarousel-module--freeBox__vacd4--1_14c";
export var freeBox__ymszf = "PlasmicProductCarousel-module--freeBox__ymszf--11bmf";
export var freeBox__onYu0 = "PlasmicProductCarousel-module--freeBox__onYu0--2aNmC";
export var linkButton__kXfP3 = "PlasmicProductCarousel-module--linkButton__kXfP3--1WOvb";
export var linkButton__cQjOh = "PlasmicProductCarousel-module--linkButton__cQjOh--VKHwr";
export var freeBox__gRjh = "PlasmicProductCarousel-module--freeBox__gRjh--2IGfN";
export var img = "PlasmicProductCarousel-module--img--1svtk";
export var freeBox__upXd9 = "PlasmicProductCarousel-module--freeBox__upXd9--2gCqZ";
export var svg__q6GjN = "PlasmicProductCarousel-module--svg__q6GjN--2TkKe";
export var svg__g2VNv = "PlasmicProductCarousel-module--svg__g2VNv--3dwuL";
export var svg__eg4Dh = "PlasmicProductCarousel-module--svg__eg4Dh--2qSnU";
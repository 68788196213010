// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--ESRAt";
export var freeBox__io24B = "PlasmicFooter-module--freeBox__io24B--2SByN";
export var freeBox__dn2Wy = "PlasmicFooter-module--freeBox__dn2Wy--TnV_0";
export var freeBox__ax3Oi = "PlasmicFooter-module--freeBox__ax3Oi--2UyoY";
export var freeBox__hlbWu = "PlasmicFooter-module--freeBox__hlbWu--3CXs_";
export var link__rzttT = "PlasmicFooter-module--link__rzttT--14HfD";
export var link__cxfjQ = "PlasmicFooter-module--link__cxfjQ--xAquy";
export var link__mRn6 = "PlasmicFooter-module--link__mRn6--2zWXt";
export var link__eGlVg = "PlasmicFooter-module--link__eGlVg--29Bq2";
export var freeBox__y71S1 = "PlasmicFooter-module--freeBox__y71S1--29mg7";
export var freeBox__h5Wrj = "PlasmicFooter-module--freeBox__h5Wrj--13gEX";
export var freeBox__kNgqU = "PlasmicFooter-module--freeBox__kNgqU--2O-_z";
export var link__qg6Qt = "PlasmicFooter-module--link__qg6Qt--EkmqP";
export var link__yhSvs = "PlasmicFooter-module--link__yhSvs--1p-H7";
export var link__sB1S9 = "PlasmicFooter-module--link__sB1S9--O0yfC";
export var link__j7Hy6 = "PlasmicFooter-module--link__j7Hy6--2xAaJ";
export var link__frka9 = "PlasmicFooter-module--link__frka9--3aj7B";
export var freeBox__n3Ypg = "PlasmicFooter-module--freeBox__n3Ypg--3YhpP";
export var freeBox___72Xs = "PlasmicFooter-module--freeBox___72Xs--2u5PP";
export var freeBox__chUpf = "PlasmicFooter-module--freeBox__chUpf--2d9pM";
export var link__at8Qg = "PlasmicFooter-module--link__at8Qg--1NMKD";
export var link__iriM = "PlasmicFooter-module--link__iriM--Orw9H";
export var link__fxjiq = "PlasmicFooter-module--link__fxjiq--3oktC";
export var link__n3V8 = "PlasmicFooter-module--link__n3V8--WQ9F6";
export var link__y8UnG = "PlasmicFooter-module--link__y8UnG--2m6aA";
export var freeBox__yA4Uk = "PlasmicFooter-module--freeBox__yA4Uk--2NBWh";
export var freeBox__b3Stv = "PlasmicFooter-module--freeBox__b3Stv--14Vtq";
export var freeBox__w4Eju = "PlasmicFooter-module--freeBox__w4Eju--ztTc0";
export var freeBox__zcuHc = "PlasmicFooter-module--freeBox__zcuHc--124fM";
export var textbox = "PlasmicFooter-module--textbox--1_3KR";
export var button = "PlasmicFooter-module--button--1qFPR";
export var svg__dRvCz = "PlasmicFooter-module--svg__dRvCz--2OoAM";
export var freeBox__kVdDn = "PlasmicFooter-module--freeBox__kVdDn--3lHHT";
export var freeBox__xOerK = "PlasmicFooter-module--freeBox__xOerK--3PYug";
export var iconLink__qmEQz = "PlasmicFooter-module--iconLink__qmEQz--3M9Wc";
export var svg__zbtTk = "PlasmicFooter-module--svg__zbtTk--1ZHlb";
export var iconLink__gvk5F = "PlasmicFooter-module--iconLink__gvk5F--1fO5t";
export var svg__u6AG8 = "PlasmicFooter-module--svg__u6AG8--ZjpD_";
export var iconLink__hPnk1 = "PlasmicFooter-module--iconLink__hPnk1--2Gldd";
export var svg__deQ5Z = "PlasmicFooter-module--svg__deQ5Z--xkLAA";
export var freeBox__j9LkB = "PlasmicFooter-module--freeBox__j9LkB--3J83f";
export var freeBox___9ZSou = "PlasmicFooter-module--freeBox___9ZSou--1mAES";
export var link___7Hkj = "PlasmicFooter-module--link___7Hkj--2DaPJ";
export var link__tCp1I = "PlasmicFooter-module--link__tCp1I--3v9Hy";
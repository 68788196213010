// extracted by mini-css-extract-plugin
export var root = "PlasmicRatings-module--root--2Ij4H";
export var freeBox = "PlasmicRatings-module--freeBox--1toHi";
export var svg__t5Gf = "PlasmicRatings-module--svg__t5Gf--3JC1x";
export var svg__oHzmo = "PlasmicRatings-module--svg__oHzmo--3kTY8";
export var svg__rop4Z = "PlasmicRatings-module--svg__rop4Z--1waOb";
export var svg__cG7AK = "PlasmicRatings-module--svg__cG7AK--34Jzt";
export var svg__f5BuB = "PlasmicRatings-module--svg__f5BuB--17iEU";
export var svg__brgtl = "PlasmicRatings-module--svg__brgtl--3qtuU";
export var svg__tNsLb = "PlasmicRatings-module--svg__tNsLb--3ZPh_";
export var svg__dqsoW = "PlasmicRatings-module--svg__dqsoW--3vLRt";
export var svg__jfq78 = "PlasmicRatings-module--svg__jfq78--JKgzw";
export var svg__rD6Ex = "PlasmicRatings-module--svg__rD6Ex--2bRbV";
export var slotNumReviews = "PlasmicRatings-module--slotNumReviews--jmHdX";
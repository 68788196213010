// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCard-module--root--1U_3p";
export var freeBox__e14F4 = "PlasmicProductCard-module--freeBox__e14F4--cE7OZ";
export var img__vYleS = "PlasmicProductCard-module--img__vYleS--guYyU";
export var freeBox__vQApK = "PlasmicProductCard-module--freeBox__vQApK--3SODi";
export var freeBox__sg4IM = "PlasmicProductCard-module--freeBox__sg4IM--1O8pf";
export var freeBox__hvoL = "PlasmicProductCard-module--freeBox__hvoL--1gSzA";
export var svg__uBz6U = "PlasmicProductCard-module--svg__uBz6U--2wdAG";
export var freeBox__dBJh = "PlasmicProductCard-module--freeBox__dBJh--1bZ6E";
export var svg__mqTce = "PlasmicProductCard-module--svg__mqTce--2gymv";
export var svg__gXkoK = "PlasmicProductCard-module--svg__gXkoK--2OdS5";
export var ratings__wiI5K = "PlasmicProductCard-module--ratings__wiI5K--ue9dk";
export var freeBox___7Ncsk = "PlasmicProductCard-module--freeBox___7Ncsk--2lyol";
export var slotTitle = "PlasmicProductCard-module--slotTitle--3xTy8";
export var slotPrice = "PlasmicProductCard-module--slotPrice--2FyM4";
// extracted by mini-css-extract-plugin
export var root = "PlasmicHome-module--root--36ffV";
export var freeBox___3ZSq = "PlasmicHome-module--freeBox___3ZSq--Ya9EU";
export var freeBox__xKtCj = "PlasmicHome-module--freeBox__xKtCj--14RaB";
export var freeBox__fLMiT = "PlasmicHome-module--freeBox__fLMiT--2K-13";
export var productCarousel = "PlasmicHome-module--productCarousel--3pL2r";
export var freeBox__dVOc7 = "PlasmicHome-module--freeBox__dVOc7--izcuj";
export var freeBox__yv7YR = "PlasmicHome-module--freeBox__yv7YR--16pAe";
export var freeBox__hl3Uf = "PlasmicHome-module--freeBox__hl3Uf--2nXmj";
export var productCard__efVvG = "PlasmicHome-module--productCard__efVvG--2_HvK";
export var img__veOqZ = "PlasmicHome-module--img__veOqZ--1LXYw";
export var ratings__nUcXg = "PlasmicHome-module--ratings__nUcXg--1o_jl";
export var productCard__w5GX9 = "PlasmicHome-module--productCard__w5GX9--UY6-y";
export var img__egjaG = "PlasmicHome-module--img__egjaG--2BMho";
export var ratings__j5OF2 = "PlasmicHome-module--ratings__j5OF2--2ET-J";
export var productCard__yipyH = "PlasmicHome-module--productCard__yipyH--3LTly";
export var img__gR6La = "PlasmicHome-module--img__gR6La--3lWAu";
export var ratings__sNgPm = "PlasmicHome-module--ratings__sNgPm--3UOBW";
export var productCard__ixPs7 = "PlasmicHome-module--productCard__ixPs7--GKlj9";
export var img__tpCfy = "PlasmicHome-module--img__tpCfy--W4CzJ";
export var ratings__v9Kxh = "PlasmicHome-module--ratings__v9Kxh--1QPDJ";
export var freeBox__k8GYr = "PlasmicHome-module--freeBox__k8GYr--HjIKz";
export var productCard__kotG = "PlasmicHome-module--productCard__kotG--1YVln";
export var img__vfD1J = "PlasmicHome-module--img__vfD1J--2gtTI";
export var ratings__t7DM5 = "PlasmicHome-module--ratings__t7DM5--1EnvR";
export var productCard__jJgVj = "PlasmicHome-module--productCard__jJgVj--2Oynf";
export var img__uucRu = "PlasmicHome-module--img__uucRu--2aApz";
export var ratings__lHfu = "PlasmicHome-module--ratings__lHfu--3ggGO";
export var productCard__iDdEz = "PlasmicHome-module--productCard__iDdEz--1htID";
export var img__irouu = "PlasmicHome-module--img__irouu--1GG-s";
export var ratings__qGaUr = "PlasmicHome-module--ratings__qGaUr--1mC0O";
export var productCard__yHJgt = "PlasmicHome-module--productCard__yHJgt--3GxDT";
export var img__u9Nrk = "PlasmicHome-module--img__u9Nrk--3E0aE";
export var ratings___0ECcN = "PlasmicHome-module--ratings___0ECcN--103Dm";
export var freeBox__w6Vsd = "PlasmicHome-module--freeBox__w6Vsd--157Va";
export var featureItem = "PlasmicHome-module--featureItem--34ZoQ";
export var freeBox__xlIWc = "PlasmicHome-module--freeBox__xlIWc--2rS-u";
export var freeBox__bep1F = "PlasmicHome-module--freeBox__bep1F--2sR_H";
export var freeBox__sfdeg = "PlasmicHome-module--freeBox__sfdeg--3TzQJ";
export var productCard__a8F3W = "PlasmicHome-module--productCard__a8F3W--fBp5D";
export var freeBox__eK8Ud = "PlasmicHome-module--freeBox__eK8Ud--R97Yo";
export var freeBox__htpKr = "PlasmicHome-module--freeBox__htpKr--mJrcu";
export var img__x5Pb = "PlasmicHome-module--img__x5Pb--2JFzd";
export var ratings__ju86G = "PlasmicHome-module--ratings__ju86G--1kQg0";
export var productCard___4DFh = "PlasmicHome-module--productCard___4DFh--1i023";
export var img__bnI8 = "PlasmicHome-module--img__bnI8--2Y3yP";
export var ratings__x2FzS = "PlasmicHome-module--ratings__x2FzS--2mtIw";
export var productCard__xeRf0 = "PlasmicHome-module--productCard__xeRf0--11BtP";
export var img__wfwhf = "PlasmicHome-module--img__wfwhf--36YmM";
export var ratings__e9GsU = "PlasmicHome-module--ratings__e9GsU--2Nxet";
export var productCard__blNOs = "PlasmicHome-module--productCard__blNOs--uX380";
export var img__xG3 = "PlasmicHome-module--img__xG3--2o3MU";
export var ratings__cn26J = "PlasmicHome-module--ratings__cn26J--SCES_";
export var freeBox__pzK3 = "PlasmicHome-module--freeBox__pzK3--376kV";
export var freeBox__pdHlp = "PlasmicHome-module--freeBox__pdHlp--3VzYC";
export var freeBox__b1RJl = "PlasmicHome-module--freeBox__b1RJl--1XWBr";
export var blogPost__mmP6Y = "PlasmicHome-module--blogPost__mmP6Y--2BOc5";
export var img__q1VIq = "PlasmicHome-module--img__q1VIq--1ndjx";
export var blogPost__itLfx = "PlasmicHome-module--blogPost__itLfx--1k7sw";
export var img__r1Qb2 = "PlasmicHome-module--img__r1Qb2--27xrm";
export var blogPost__r9A17 = "PlasmicHome-module--blogPost__r9A17--1DkTe";
export var img__hrXZq = "PlasmicHome-module--img__hrXZq--2yCM3";
export var freeBox__z5Kjc = "PlasmicHome-module--freeBox__z5Kjc--1Ch2U";
export var img__wsJxp = "PlasmicHome-module--img__wsJxp--3e2l7";
export var img__zGdqJ = "PlasmicHome-module--img__zGdqJ--1HcVH";
export var img___4G0Eg = "PlasmicHome-module--img___4G0Eg--1CRph";
export var img__qONn = "PlasmicHome-module--img__qONn--wvMgt";
export var img__nuOze = "PlasmicHome-module--img__nuOze--197-7";
export var footer = "PlasmicHome-module--footer--2lwsL";
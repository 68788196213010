// extracted by mini-css-extract-plugin
export var root = "PlasmicBlogPost-module--root--vJpz1";
export var root__long = "PlasmicBlogPost-module--root__long--1eQ84";
export var freeBox__uHib1 = "PlasmicBlogPost-module--freeBox__uHib1--3MRtC";
export var img__oqNTw = "PlasmicBlogPost-module--img__oqNTw--MYh16";
export var freeBox__mDk0X = "PlasmicBlogPost-module--freeBox__mDk0X--3uu1P";
export var freeBox__long__mDk0XFZvhJ = "PlasmicBlogPost-module--freeBox__long__mDk0XFZvhJ--rVkc7";
export var slotTitle = "PlasmicBlogPost-module--slotTitle--YMQxe";
export var slotAuthor = "PlasmicBlogPost-module--slotAuthor--1gJX4";
export var slotDescription = "PlasmicBlogPost-module--slotDescription--3LK_k";
export var slotDescription__long = "PlasmicBlogPost-module--slotDescription__long--1JBVA";